.nadiahealthcare{
    background-color: rgb(118, 3, 83);
    padding: 2px;
}

.buttonhome{
    background-color:  rgb(113, 29, 76); /* Green */
    border: none;
    color: white;
    padding: 5px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border-radius: 6px;
  }

  .besttourismnadia{
    height:  380px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: url(https://i.postimg.cc/brZ10sZB/lab-1.jpg);
    background-attachment: fixed;
    color: white;  
    align-items: center;
}

.radiusimage img{
  border-radius: 14px;
}


/* src/App.css */
/* ... Existing CSS styles ... */

.add-to-cart-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .add-to-cart-button button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .add-to-cart-button button:hover {
    background-color: #0056b3;
  }
  
  .add-to-cart-button span {
    font-size: 24px;
    margin: 0 10px;
  }
  
.employeelogin{
    background-color: rgb(118, 3, 83); /* Green */
    border: none;
    color: white;
    padding: 5px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border-radius: 6px;
  }

  .colors{
    background-color: antiquewhite;
padding: 8px;
  }


  .button {
    background-color: #d4d2e3; /* Green */
    border: none;
    color: rgb(122, 248, 255);
    padding: 5px 14px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
  
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 6px;
  }
  
  .button1 {
    background-color: rgb(37, 25, 168); 
    color: black; 
    border: 2px solid #c8c7d1;
  }
  
  .button1:hover {
    background-color: #700859;
    color: white;
  }



  .headlogo{
    margin-left: 25px;
    display: flex;
    width:210px;
    height: 50px;
}
.logandsign{
    margin-left: 20px;
    padding: 25px;
     
    /* padding-right: 10px;
    letter-spacing: 1.5px; */
}
/* .first{
    margin:02px 03px;
    padding: 0 20px;
    border: 1px solid #46c4d4;
    border-radius: 25px;
    cursor: pointer;
    overflow: hidden;
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);   
} */
.first :hover {
    transform: scale(1.05);
}
/* .for_providers{
    font-size: 20px;
    margin-top: 20px;
    margin-left: 20px;
} */
.text-end{
   
    padding-right: 48px;
    letter-spacing: 1.5px;
}
.userphoto {
  margin-left:10px;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border-width: 4px;
  border-color: rgb(72, 173, 220);
  border-style: outset;
}

.logosider{
  margin-left: 10px;
}

.text {
    font-family: 'Ubuntu', sans-serif;
}

.buttonlogin {
    background-color: #0c4f61; /* Green */
    border: none;
    color: white;
    padding: 6px 15px;
   
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border-radius: 13px;
  }



  

  .dropdown-menu li {
    position: relative;
    }
    .dropdown-menu .dropdown-submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
    }
    .dropdown-menu .dropdown-submenu-left {
    right: 100%;
    left: auto;
    }
    .dropdown-menu > li:hover > .dropdown-submenu {
    display: block;
    }






    .downloadbutton{
      background-color: rgb(28, 111, 137); /* Green */
      border: none;
      color: white;
      padding: 5px 16px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 14px;
      border-radius: 6px;
    }

    
    .loginbutton{
      background-color: rgb(118, 3, 83); /* Green */
      border: none;
      color: white;
      padding: 5px 16px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 14px;
      border-radius: 6px;
    }